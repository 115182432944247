import axios from "axios"
import { signInWithEmailAndPassword } from "firebase/auth"
import { doc, setDoc } from "firebase/firestore"
import React, { useState } from "react"
import { auth, db } from "../../../firebase-config"
import { getCustomClaims, getUIDMain, getUserToken } from "../../service/user-service"
import { ENABLE_ERROR_LOGGING, ENDPOINT_128, ENDPOINT_512 } from "../../settings/project-settings"
import { createGlobalStyle } from "styled-components"
import { callEndpoint } from "../../service/endpoints-service"
import { QuickLogin, TestBoxBottonizedResponses, TestBoxLine, TestBoxV2, getObjectPrintedByLine } from "../../client-ui/test/shared"
import SpacerV from "../../locust/sections/SpacerV"

const WORKING_UID = 'oe7JHprqoAUe09o66gxgftFHvyr2'


export const GlobalStyle = createGlobalStyle`
*{
  margin:0;
  padding:0;
}
html, body {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: visible;
    -webkit-overflow-scrolling: touch;
    background-color : whitesmoke;
  }
  body {
    position: relative;
    width: 100%;
  }
  img{
    max-width:100%;
 }
`;

export default() => {
    const [currentUser, setCurrentUser] = React.useState("NOT LOGGED IN!")
    const [token, setToken] = React.useState("NOT LOGGED IN!")

    const [read, setRead] = React.useState("-")
    React.useEffect(()=>{
        auth.onAuthStateChanged(()=>{
            if( auth.currentUser ) setCurrentUser(auth.currentUser.uid)
            else setCurrentUser("failed to login")
            
            let anyAuth : any = auth
            if( anyAuth?.currentUser?.accessToken )
                setToken( anyAuth.currentUser.accessToken )
            
        })
        
    })


    
    return (
        <>
            <GlobalStyle/>

            <div> logged in as: {currentUser}</div>
            <div> logged in as: {JSON.stringify((auth as any)?.currentUser?.email)}</div>
            
            <QuickLogin >
                Login
            </QuickLogin>
            
            
            
            <TestBoxV2 defaultUid={WORKING_UID} title="Get User Custom Claims" onClick={ async ( setResponse : any, field ?: string )=>{

                const response : any = await callEndpoint({ 
                    key : "admin-get-user-custom-claims-by-uid", 
                    token : token,
                    endpoint : ENDPOINT_128,
                    params : {
                        targetUid : WORKING_UID
                    },
                })

                setResponse(<>
                    
                    <TestBoxLine>
                        {`uid: ${getUIDMain()}`}
                    </TestBoxLine>
                    
                    <TestBoxLine>

                        { getObjectPrintedByLine(await getCustomClaims()) }

                        <SpacerV h={50}/>
                    </TestBoxLine>  

                </>)

            }}/>
            
            <TestBoxV2 defaultUid={WORKING_UID} title="Get Payment Tier (paid) from Custom Claims" onClick={ async ( setResponse : any, field ?: string )=>{

                const response : any = await callEndpoint({ 
                    key : "admin-get-user-custom-claims-by-uid", 
                    token : token,
                    endpoint : ENDPOINT_128,
                    params : {
                        targetUid : WORKING_UID
                    },
                })

                setResponse(<>
                    
                    <TestBoxLine>
                        {`uid: ${getUIDMain()}`}
                    </TestBoxLine>
                    
                    <TestBoxLine>

                        {`paymentTier: ${ JSON.stringify((await getCustomClaims() as any).paid) }`}
                        
                        <SpacerV h={50}/>
                    </TestBoxLine>  

                </>)

            }}/>

            <SpacerV h={100}/>
        </>
    )
}


